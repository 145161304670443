.rs-picker-date-menu .rs-calendar-table-cell-week-number{
  background-color: black;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.rs-picker-date-menu .rs-calendar-table-row:not(.rs-calendar-table-header-row):hover .rs-calendar-table-cell-content {
  /* background-color: rgba(204,233,255,.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); */
  color: #FFB81C;
  background-color: rgba(254, 107, 13, 0.25);
}

.rs-picker-date-menu .rs-calendar-table-row .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  background-color: #FFB81C;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #FFB81C !important;
}

.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
  -webkit-box-shadow: 0 0 0 3px rgb(from #FFB81C r g b / 25%) !important;
  box-shadow: 0 0 0 3px rgb(from #FFB81C r g b / 25%) !important;
}

.rs-btn-primary {
  background-color: #FFB81C !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #FFB81C;
}

.so-week-picker .weekInfos{
  margin-top: 20px;
}
