.copy-from-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.copy-from-body .rs-picker {
  margin-bottom: 8px;
}

.confirmation-modal-body .confirmation-modal-body__weeks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
}

.confirmation-modal-body .prev-week {
  background-color: #fe995a;
}