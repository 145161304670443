/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
} */

/* body::after {
  background: url("./assets/images/03.jpg");
  content: "";
  opacity: 0.4;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;   
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

p.highlight {
  color: #FFB81C;
}

p.normal {
  font-weight: normal;
  font-size: 15px;
}

p.thick {
  font-weight: bold;
  font-size: large;
}

p.thicker {
  font-weight: 900;
  font-size: 150%;
}
.t-shade {
  box-shadow: 0 0 10px 1px rgb(193 193 193);
}
.bg-header {
  background: #bfbfbf40 !important;
  border-radius: 5px !important;
}
.shade-row {
  box-shadow: 1px 1.2px 7px 1px #00000038;
}
.border-bottom {
  border-bottom: 1px solid grey;
}
.react-grid-Main {
  outline: none;
  box-shadow: 0 0 10px 1px rgb(193 193 193);
  border-radius: 8px;
  padding: 8px;
  max-width: 1236px !important;
}
.react-grid-Grid {
  /* min-height: calc( 100vh ) !important;  */
  border: none !important;
  outline: none !important;
  font-family: sans-serif !important;
  /* border-color: orange !important; */
  padding: 4px;
}

.react-grid-Container {
  /* min-height: calc( 100vh - 50px) !important;  */
  background-color: white !important;
  width: 100% !important;
}

/* .react-grid-Canvas {
  min-height: calc( 100vh ) !important;
  background-color: white !important;
} */

.react-grid-Header {
  background-color: #f7f7f7 !important;
}
.react-grid-Cell:focus {
  outline: none !important;
  border: none !important;
}

.react-grid-Row {
  margin: 8px;
  box-shadow: 0 0 10px 1px rgb(193 193 193);
  border-radius: 8px;
}
.react-grid-Cell {
  background-color: transparent !important;
  color: black !important;
  border: none;
  outline: none !important;
}
.react-grid-HeaderRow {
  width: 100% !important;
}
.react-grid-HeaderCell {
  background-color: #f7f7f7 !important;
  color: black !important;
  border: none;
}
.border-right {
  border: 1px solid grey !important;
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.border-0 {
  border: none !important;
}
.bg-org {
  background-color: #FFB81C;
  border-radius: 5px;
  font-weight: bold;
}
.bg-org-1 {
  background-color: #FFB81C;
  font-weight: bold;
}
.grey {
  color: grey;
}
.h-fit {
  height: fit-content !important;
}
a:-webkit-any-link:focus {
  outline-offset: none !important;
}
:focus {
  outline: none !important;
}
.ctm-btn {
  height: 3rem;
  text-align: center;
  line-height: 2.5rem;
}
.ctm-btn-1 {
  height: 2.5rem;
  text-align: center;
  line-height: 2rem;
}
.bg-sec {
  background-color: black;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.bg-sec-1 {
  background-color: black;
  color: white;
  font-weight: bold;
}
.ad-grid-mr {
  margin: 0px 16px;
}
.larger-font {
  font-size: xx-large;
}
.bg-danger {
  background-color: #dc3545 !important;
}
.color-white {
  color: white !important;
}
.bg-green {
  background-color: green !important;
}
.v-align {
  vertical-align: -80%;
}
@media (min-width: 1050px) {
  .lg-w-75 {
    width: 75%;
  }
}
