/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
}

.nav-strip {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-bottom: 20px;
}

.content {
  padding: 20px;
  flex: 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-strip {
    justify-content: center;
  }

  .quick-link-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .search-box {
    width: 100%; /* Full width on smaller screens */
    height: 40px;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
  }
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Quick Links Container */
.quick-links-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Quick Link Buttons */
.quick-link-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.quick-link-button:hover {
  background-color: #0056b3;
}

/* Search Box */
.search-box {
  width: 500px; /* Default width */
  height: 40px; /* Adjust height */
  padding: 10px 20px; /* Add padding */
  border: 1px solid #ccc;
  border-radius: 20px; /* Make it round */
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  outline: none; /* Remove default outline */
  transition: box-shadow 0.3s ease; /* Smooth transition for focus */
}

.search-box:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on focus */
}

/* Subheading */
.subheading h2 {
  cursor: pointer;
  color: #333;
}

.subheading div {
  margin-left: 20px;
}

/* Floating Button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}