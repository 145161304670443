* {
  font-family: 'Noto Sans', sans-serif;
}

.App-logo {
  height: 120px;
  padding-left: 20px;
}

.App-header {
  /* background-color: black; */
  height: 150px;
  padding: 20px;
  /* color: white; */
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  --scrollbar-foreground: #c3c1bc;
  --scrollbar-background: rgba(255, 255, 255, 0);
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
::-webkit-scrollbar {
  width: 8px; /* Mostly for vertical scrollbars */
  height: 8px; /* Mostly for horizontal scrollbars */
}
::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 10px;
}
::-webkit-scrollbar-track { /* Background */
  background: var(--scrollbar-background);
}

#toast-container > div {
  opacity: 1;
}

body {
  background-color: white !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
body::after {
  background: linear-gradient(rgb(255 255 255 / 95%), rgb(249 249 249 / 50%)),
    url("./assets/images/image.jpg");
  content: "";
  opacity: 0.7;
  background-repeat: no-repeat;
  background-position-x: right;
  position: absolute;
  background-size: contain;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.row {
  justify-content: center;
  /* margin: 2%!important; */
  /* padding: 0%!important; */
}
.so-orange {
  /* color: #FFB81C; */
  color: #FFB81C;
}
/* @media (min-width: 400px) {
  .root {
    max-width: 400px!important;
  }
}

@media (min-width: 768px) {
  .root {
    max-width: 750px!important;
  }
}

@media (min-width: 1024px) {
  .root {
    max-width: 1000px!important;
  }
}

@media (min-width: 1280px) {
  .root {
    max-width: 1200px!important;
  }
}

@media (min-width: 1366px) {
  .root {
    max-width: 1300px!important;
  }
}

@media (min-width: 1920px) {
  .root {
    max-width: 1800px!important;
  }
} */

.root {
  min-height: 100vh !important;
}

.container {
  height: 80vh;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}

.navbar {
  margin-bottom: 5% !important;
  margin-top: 1% !important;
}

.card {
  background-color: black;
  border-radius: 8px !important;
  align-items: center !important;
}
.card-body {
  width: 100%;
}
.card-title {
  font-size: 1em;
  min-height: auto;
  padding: 14px;
  margin-bottom: 0 !important;
}
.card-title-ad-pad {
  font-size: 1em;
  min-height: auto;
  padding: 5px 14px;
  margin-bottom: 0 !important;
}
a:hover {
  text-decoration: none !important;
}
.bg-patern {
  background-image: url(./assets/images/Assets/pattern.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 10vw;
  width: 100%;
}
.shade {
  box-shadow: 0 0 10px 1px rgb(193 193 193);
}
.xl {
  font-size: x-large;
}
.b-round {
  border-radius: 8px;
}
.card-body {
  height: 160px;
}
.card-text {
  font-size: 1rem;
}

.inner {
  overflow: hidden;
}

.inner div {
  transition: all 0.8s ease;
}

.inner:hover div {
  transform: scale(1.3);
}

.card-img-top {
  max-width: 50%;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:visited {
  background-color: #FFB81C !important;
  border-color: whitesmoke !important;
}
.justify-content-left {
  justify-content: left !important;
}
.justify-content-space-around {
  justify-content: space-around !important;
}
.home-btn {
  background-color: #FFB81C !important;
  border-color: black !important;
}

.nav-btn {
  background-color: #FFB81C !important;
  border-color: whitesmoke !important;
}

.profile-img {
  max-width: 65px;
  max-height: 65px;
}

.pointer {
  cursor: pointer;
}

.br-8 {
  border-radius: 8px;
}

.shade-0 {
  box-shadow: none !important;
}
.shade-1 {
  box-shadow: 0 0 10px 1px #FFB81C;
}
.nav-org {
  color: #FFB81C !important;
}
.invert-5 {
  filter: invert(0.5);
}
.grey {
  color: grey;
}
.bg-home {
  background-color: #efefef;
  border-radius: 0px 10px 10px 0px;
}
.border-0 {
  border: 0 !important;
}
div .offline {
  color: #FFB81C !important;
}
.dot {
  content: "";
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: grey;
  position: absolute;
  right: 31%;
}
.b-left-org {
  border-left: 3px solid #FFB81C;
}
.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6vw;
}
.b-org {
  border: 1px solid #FFB81C;
}
.MuiDrawer-paper {
  width: auto !important;
  box-shadow: 1px 0px 5px 2px #00000026;
}
.disp-block {
  display: block;
}
.table-font {
  font-weight: 500;
  font-size: 1rem;
}
.no-rcd {
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
}
/* .note-icon {
  display: inline-block;
  margin-left: 25px;
}
.note-icon-mob {
  display: inline-block;
} */
.open {
  width: 40px;
}
.open-mob {
  width: 46px;
}
.note {
  width: 75%;
}
.note-added {
  width: 75%;
  border-radius: 5px;
  font-weight: bold;
  height: 36px;
  width: 36px;
}

.note-mob {
  width: 42px;
}
@media (min-width: 600px) {
  .makeStyles-drawerClose-28 {
    width: auto !important;
  }
  .makeStyles-drawerClose-8 {
    width: auto !important;
  }
}
@media (max-width: 1030px) {
  h4 {
    font-size: 1rem !important;
  }
  h5 {
    font-size: 1rem !important;
  }
}
@media (max-width: 768px) {
  .md-h4 {
    font-size: 0.8rem !important;
  }
  .md-h5 {
    font-size: 0.78rem !important;
  }
  .md-small {
    font-size: small !important;
  }
}
