.page-container {
  height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.order-card {
  background: #fff;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  min-height: 180px;
  padding: 8px 0;
}

.order-grid {
  flex: 1 1 auto;
  height: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  margin-top: 0.75rem;
  border-radius: 4px;
}

.order__metadata {
  display: flex;
}

.sort-by-dd {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #cecece;
}

.filter-controls {
  background: #fff;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  padding: 10px;
}

.order-list {
  overflow: auto;
  height: 100%;
}

.item__notes-label {
  font-weight: bold;
}

.item__notes-content {
  text-align: justify;
}

.item__show-notes-button {
  margin-left: 4px;
  color: gray;
  font-size: small;
  cursor: pointer;
}

.header {
  border-bottom: 1px solid #cecece;
  padding: 0.5em 1em;
}

.delivery-details {
  border-bottom: 1px solid #cecece;
  padding: 0.5em 1em;
}

.order-number {
  font-weight: bold;
}

.updated-time {
  background-color: #cecece;
  padding: 0.15em 0.5em;
  border-radius: 15px;
}

.customer-address {
  width: 40%;
  text-align: justify;
}

.items-section {
  padding: 0.5em 1em;
}

.item {
  padding-bottom: 0.25em;
}

.item__item-name {
  text-align: justify;
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}

.item__item-quantity {
  background-color: #FFB81C;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
}

.item__item-notes {
  font-size: small;
  margin-left: 8px;
}

.fulfillment-time {
  padding: 0.15em 0.5em;
  border-radius: 15px;
}

.customer-notes {
  display: block;
  border-radius: 10px;
  padding: 4px;
  background: #fffb5d;
  border: 1px dashed black;
  box-sizing: border-box;
  white-space: pre-line;
  /* or pre-wrap */
}