.planning-action-menu {
  position: relative;
}

.planning-action-menu__ellipsis {
  font-size: 24px;
}

.planning-action-menu__menu {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #FFB81C;
  padding: 8px;
  border-radius: 5px;
  width: 250px;
  animation: createMenu 0.5s;
  transform-origin: right;
}

@keyframes createMenu {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.planning-action-menu__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.planning-action-menu__menu li {
  padding: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.planning-action-menu__menu li:hover {
  cursor: pointer;
  background-color: #FFB81C;
  color: #fff;
}

.planning-action-menu__menu li:hover svg {
  fill: #fff;
}

.planning-action-menu__menu li svg {
  margin-right: 8px;
}

.planning-action-menu__menu .planning-action-menu__dummy-action:hover {
  cursor: not-allowed;
}

.rs-loader {
  margin-left: 8px
}