.so-panel {
  min-height: auto;
  border: 1px solid #FFB81C;
  border-radius: 10px;
  margin: 10px 0;
}

.so-panel-header {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
}

.so-panel-header__header {
  flex-grow: 1;
}

.so-panel-header__toggle-button {
  margin-right: 16px;
  cursor: pointer;
}

.so-panel-header__toggle-button svg {
  fill: #FFB81C;
}
