* {
  font-family: 'Noto Sans', sans-serif;
}

.App-logo {
  height: 120px;
  padding-left: 20px;
}

.App-header {
  /* background-color: black; */
  height: 150px;
  padding: 20px;
  /* color: white; */
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  --scrollbar-foreground: #c3c1bc;
  --scrollbar-background: rgba(255, 255, 255, 0);
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
::-webkit-scrollbar {
  width: 8px; /* Mostly for vertical scrollbars */
  height: 8px; /* Mostly for horizontal scrollbars */
}
::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 10px;
}
::-webkit-scrollbar-track { /* Background */
  background: var(--scrollbar-background);
}

#toast-container > div {
  opacity: 1;
}

body {
  background-color: white !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
}
body::after {
  background: linear-gradient(rgb(255 255 255 / 95%), rgb(249 249 249 / 50%)),
    url(/static/media/image.f4b80dcd.jpg);
  content: "";
  opacity: 0.7;
  background-repeat: no-repeat;
  background-position-x: right;
  position: absolute;
  background-size: contain;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.row {
  justify-content: center;
  /* margin: 2%!important; */
  /* padding: 0%!important; */
}
.so-orange {
  /* color: #FFB81C; */
  color: #FFB81C;
}
/* @media (min-width: 400px) {
  .root {
    max-width: 400px!important;
  }
}

@media (min-width: 768px) {
  .root {
    max-width: 750px!important;
  }
}

@media (min-width: 1024px) {
  .root {
    max-width: 1000px!important;
  }
}

@media (min-width: 1280px) {
  .root {
    max-width: 1200px!important;
  }
}

@media (min-width: 1366px) {
  .root {
    max-width: 1300px!important;
  }
}

@media (min-width: 1920px) {
  .root {
    max-width: 1800px!important;
  }
} */

.root {
  min-height: 100vh !important;
}

.container {
  height: 80vh;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}

.navbar {
  margin-bottom: 5% !important;
  margin-top: 1% !important;
}

.card {
  background-color: black;
  border-radius: 8px !important;
  align-items: center !important;
}
.card-body {
  width: 100%;
}
.card-title {
  font-size: 1em;
  min-height: auto;
  padding: 14px;
  margin-bottom: 0 !important;
}
.card-title-ad-pad {
  font-size: 1em;
  min-height: auto;
  padding: 5px 14px;
  margin-bottom: 0 !important;
}
a:hover {
  text-decoration: none !important;
}
.bg-patern {
  background-image: url(/static/media/pattern.45c61c99.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 10vw;
  width: 100%;
}
.shade {
  box-shadow: 0 0 10px 1px rgb(193 193 193);
}
.xl {
  font-size: x-large;
}
.b-round {
  border-radius: 8px;
}
.card-body {
  height: 160px;
}
.card-text {
  font-size: 1rem;
}

.inner {
  overflow: hidden;
}

.inner div {
  transition: all 0.8s ease;
}

.inner:hover div {
  transform: scale(1.3);
}

.card-img-top {
  max-width: 50%;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:visited {
  background-color: #FFB81C !important;
  border-color: whitesmoke !important;
}
.justify-content-left {
  justify-content: left !important;
}
.justify-content-space-around {
  justify-content: space-around !important;
}
.home-btn {
  background-color: #FFB81C !important;
  border-color: black !important;
}

.nav-btn {
  background-color: #FFB81C !important;
  border-color: whitesmoke !important;
}

.profile-img {
  max-width: 65px;
  max-height: 65px;
}

.pointer {
  cursor: pointer;
}

.br-8 {
  border-radius: 8px;
}

.shade-0 {
  box-shadow: none !important;
}
.shade-1 {
  box-shadow: 0 0 10px 1px #FFB81C;
}
.nav-org {
  color: #FFB81C !important;
}
.invert-5 {
  filter: invert(0.5);
}
.grey {
  color: grey;
}
.bg-home {
  background-color: #efefef;
  border-radius: 0px 10px 10px 0px;
}
.border-0 {
  border: 0 !important;
}
div .offline {
  color: #FFB81C !important;
}
.dot {
  content: "";
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: grey;
  position: absolute;
  right: 31%;
}
.b-left-org {
  border-left: 3px solid #FFB81C;
}
.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6vw;
}
.b-org {
  border: 1px solid #FFB81C;
}
.MuiDrawer-paper {
  width: auto !important;
  box-shadow: 1px 0px 5px 2px #00000026;
}
.disp-block {
  display: block;
}
.table-font {
  font-weight: 500;
  font-size: 1rem;
}
.no-rcd {
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
}
/* .note-icon {
  display: inline-block;
  margin-left: 25px;
}
.note-icon-mob {
  display: inline-block;
} */
.open {
  width: 40px;
}
.open-mob {
  width: 46px;
}
.note {
  width: 75%;
}
.note-added {
  width: 75%;
  border-radius: 5px;
  font-weight: bold;
  height: 36px;
  width: 36px;
}

.note-mob {
  width: 42px;
}
@media (min-width: 600px) {
  .makeStyles-drawerClose-28 {
    width: auto !important;
  }
  .makeStyles-drawerClose-8 {
    width: auto !important;
  }
}
@media (max-width: 1030px) {
  h4 {
    font-size: 1rem !important;
  }
  h5 {
    font-size: 1rem !important;
  }
}
@media (max-width: 768px) {
  .md-h4 {
    font-size: 0.8rem !important;
  }
  .md-h5 {
    font-size: 0.78rem !important;
  }
  .md-small {
    font-size: small !important;
  }
}

.so-report-search-bar {
  display: flex;
  align-items: center;
}

.so-report-search-bar  .switch.btn {
  margin-left: 8px;
}
.so-file-guide {
  display: inline;
}

.so-file-guide:hover {
  cursor: pointer;
}
.planning-action-menu {
  position: relative;
}

.planning-action-menu__ellipsis {
  font-size: 24px;
}

.planning-action-menu__menu {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 5;
  background-color: #fff;
  border: 1px solid #FFB81C;
  padding: 8px;
  border-radius: 5px;
  width: 250px;
  animation: createMenu 0.5s;
  transform-origin: right;
}

@keyframes createMenu {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.planning-action-menu__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.planning-action-menu__menu li {
  padding: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.planning-action-menu__menu li:hover {
  cursor: pointer;
  background-color: #FFB81C;
  color: #fff;
}

.planning-action-menu__menu li:hover svg {
  fill: #fff;
}

.planning-action-menu__menu li svg {
  margin-right: 8px;
}

.planning-action-menu__menu .planning-action-menu__dummy-action:hover {
  cursor: not-allowed;
}

.rs-loader {
  margin-left: 8px
}
.rs-picker-date-menu .rs-calendar-table-cell-week-number{
  background-color: black;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.rs-picker-date-menu .rs-calendar-table-row:not(.rs-calendar-table-header-row):hover .rs-calendar-table-cell-content {
  /* background-color: rgba(204,233,255,.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); */
  color: #FFB81C;
  background-color: rgba(254, 107, 13, 0.25);
}

.rs-picker-date-menu .rs-calendar-table-row .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  background-color: #FFB81C;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #FFB81C !important;
}

.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
  box-shadow: 0 0 0 3px rgb(from #FFB81C r g b / 25%) !important;
}

.rs-btn-primary {
  background-color: #FFB81C !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #FFB81C;
}

.so-week-picker .weekInfos{
  margin-top: 20px;
}

.so-week-info {
  border: 1px solid #FFB81C;
  padding: 8px;
  display: inline-block;
  background-color: #FFB81C;
  color: #fff;
  border-radius: 5px;
}
.copy-from-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.copy-from-body .rs-picker {
  margin-bottom: 8px;
}

.confirmation-modal-body .confirmation-modal-body__weeks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
}

.confirmation-modal-body .prev-week {
  background-color: #fe995a;
}
.page-container {
  height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
}

.order-card {
  background: #fff;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  min-height: 180px;
  padding: 8px 0;
}

.order-grid {
  flex: 1 1 auto;
  height: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  margin-top: 0.75rem;
  border-radius: 4px;
}

.order__metadata {
  display: flex;
}

.sort-by-dd {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #cecece;
}

.filter-controls {
  background: #fff;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 1rem;
  padding: 10px;
}

.order-list {
  overflow: auto;
  height: 100%;
}

.item__notes-label {
  font-weight: bold;
}

.item__notes-content {
  text-align: justify;
}

.item__show-notes-button {
  margin-left: 4px;
  color: gray;
  font-size: small;
  cursor: pointer;
}

.header {
  border-bottom: 1px solid #cecece;
  padding: 0.5em 1em;
}

.delivery-details {
  border-bottom: 1px solid #cecece;
  padding: 0.5em 1em;
}

.order-number {
  font-weight: bold;
}

.updated-time {
  background-color: #cecece;
  padding: 0.15em 0.5em;
  border-radius: 15px;
}

.customer-address {
  width: 40%;
  text-align: justify;
}

.items-section {
  padding: 0.5em 1em;
}

.item {
  padding-bottom: 0.25em;
}

.item__item-name {
  text-align: justify;
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}

.item__item-quantity {
  background-color: #FFB81C;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
}

.item__item-notes {
  font-size: small;
  margin-left: 8px;
}

.fulfillment-time {
  padding: 0.15em 0.5em;
  border-radius: 15px;
}

.customer-notes {
  display: block;
  border-radius: 10px;
  padding: 4px;
  background: #fffb5d;
  border: 1px dashed black;
  box-sizing: border-box;
  white-space: pre-line;
  /* or pre-wrap */
}
.so-panel {
  min-height: auto;
  border: 1px solid #FFB81C;
  border-radius: 10px;
  margin: 10px 0;
}

.so-panel-header {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #ddd;
  border-radius: 10px;
}

.so-panel-header__header {
  flex-grow: 1;
}

.so-panel-header__toggle-button {
  margin-right: 16px;
  cursor: pointer;
}

.so-panel-header__toggle-button svg {
  fill: #FFB81C;
}

.so-prep-list .rmsc.multi-select {
  margin-left: 16px;
  width: 180px;
}

.so-prep-list .rmsc.multi-select .dropdown-container {
  height: 100%;
}

.so-prep-panel-header {
  font-size: larger;
  font-weight: bold;
  padding-left: 30px;
}

.so-prep-list .so-panel-header {
  /* background-color: rgba(254, 107, 13, 0.35); */
  background-color: #eeeeee;
}
/* fix for ace editor cursor position */

.jsoneditor {
  height: 60vh;
  border: thin solid #FFB81C;
}

.jsoneditor-menu {
  background-color: #FFB81C;
  color: #fff;
  border-bottom: none;
}

.ace_editor,
.ace_editor * {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Droid Sans Mono', 'Consolas', monospace !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}
/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
}

.nav-strip {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-bottom: 20px;
}

.content {
  padding: 20px;
  flex: 1 1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-strip {
    justify-content: center;
  }

  .quick-link-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .search-box {
    width: 100%; /* Full width on smaller screens */
    height: 40px;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
  }
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Quick Links Container */
.quick-links-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

/* Quick Link Buttons */
.quick-link-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.quick-link-button:hover {
  background-color: #0056b3;
}

/* Search Box */
.search-box {
  width: 500px; /* Default width */
  height: 40px; /* Adjust height */
  padding: 10px 20px; /* Add padding */
  border: 1px solid #ccc;
  border-radius: 20px; /* Make it round */
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  outline: none; /* Remove default outline */
  transition: box-shadow 0.3s ease; /* Smooth transition for focus */
}

.search-box:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on focus */
}

/* Subheading */
.subheading h2 {
  cursor: pointer;
  color: #333;
}

.subheading div {
  margin-left: 20px;
}

/* Floating Button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
} */

/* body::after {
  background: url("./assets/images/03.jpg");
  content: "";
  opacity: 0.4;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;   
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

p.highlight {
  color: #FFB81C;
}

p.normal {
  font-weight: normal;
  font-size: 15px;
}

p.thick {
  font-weight: bold;
  font-size: large;
}

p.thicker {
  font-weight: 900;
  font-size: 150%;
}
.t-shade {
  box-shadow: 0 0 10px 1px rgb(193 193 193);
}
.bg-header {
  background: #bfbfbf40 !important;
  border-radius: 5px !important;
}
.shade-row {
  box-shadow: 1px 1.2px 7px 1px #00000038;
}
.border-bottom {
  border-bottom: 1px solid grey;
}
.react-grid-Main {
  outline: none;
  box-shadow: 0 0 10px 1px rgb(193 193 193);
  border-radius: 8px;
  padding: 8px;
  max-width: 1236px !important;
}
.react-grid-Grid {
  /* min-height: calc( 100vh ) !important;  */
  border: none !important;
  outline: none !important;
  font-family: sans-serif !important;
  /* border-color: orange !important; */
  padding: 4px;
}

.react-grid-Container {
  /* min-height: calc( 100vh - 50px) !important;  */
  background-color: white !important;
  width: 100% !important;
}

/* .react-grid-Canvas {
  min-height: calc( 100vh ) !important;
  background-color: white !important;
} */

.react-grid-Header {
  background-color: #f7f7f7 !important;
}
.react-grid-Cell:focus {
  outline: none !important;
  border: none !important;
}

.react-grid-Row {
  margin: 8px;
  box-shadow: 0 0 10px 1px rgb(193 193 193);
  border-radius: 8px;
}
.react-grid-Cell {
  background-color: transparent !important;
  color: black !important;
  border: none;
  outline: none !important;
}
.react-grid-HeaderRow {
  width: 100% !important;
}
.react-grid-HeaderCell {
  background-color: #f7f7f7 !important;
  color: black !important;
  border: none;
}
.border-right {
  border: 1px solid grey !important;
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.border-0 {
  border: none !important;
}
.bg-org {
  background-color: #FFB81C;
  border-radius: 5px;
  font-weight: bold;
}
.bg-org-1 {
  background-color: #FFB81C;
  font-weight: bold;
}
.grey {
  color: grey;
}
.h-fit {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
a:-webkit-any-link:focus {
  outline-offset: none !important;
}
:focus {
  outline: none !important;
}
.ctm-btn {
  height: 3rem;
  text-align: center;
  line-height: 2.5rem;
}
.ctm-btn-1 {
  height: 2.5rem;
  text-align: center;
  line-height: 2rem;
}
.bg-sec {
  background-color: black;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.bg-sec-1 {
  background-color: black;
  color: white;
  font-weight: bold;
}
.ad-grid-mr {
  margin: 0px 16px;
}
.larger-font {
  font-size: xx-large;
}
.bg-danger {
  background-color: #dc3545 !important;
}
.color-white {
  color: white !important;
}
.bg-green {
  background-color: green !important;
}
.v-align {
  vertical-align: -80%;
}
@media (min-width: 1050px) {
  .lg-w-75 {
    width: 75%;
  }
}

