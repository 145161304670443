/* fix for ace editor cursor position */

.jsoneditor {
  height: 60vh;
  border: thin solid #FFB81C;
}

.jsoneditor-menu {
  background-color: #FFB81C;
  color: #fff;
  border-bottom: none;
}

.ace_editor,
.ace_editor * {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Droid Sans Mono', 'Consolas', monospace !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}