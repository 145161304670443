.so-prep-list .rmsc.multi-select {
  margin-left: 16px;
  width: 180px;
}

.so-prep-list .rmsc.multi-select .dropdown-container {
  height: 100%;
}

.so-prep-panel-header {
  font-size: larger;
  font-weight: bold;
  padding-left: 30px;
}

.so-prep-list .so-panel-header {
  /* background-color: rgba(254, 107, 13, 0.35); */
  background-color: #eeeeee;
}